<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h6 class="card-header">
        <cds-icon shape="gavel" size="md"></cds-icon>
        Datenschutz
        <span style="float: right">
          <cds-icon shape="view-list" size="md"></cds-icon>
        </span>
      </h6>

      <div class="card-block">
        <!-- <h4 class="card-title">Title</h4> -->
        <div class="card-text">
          <app-datenschutz></app-datenschutz>
        </div>
      </div>

      <!-- <div class="card-footer"></div> -->
    </div>
  </div>
</div>
