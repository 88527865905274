import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { ClrAddonsModule } from '@porscheinformatik/clr-addons';
import { InfoRoutingModule } from './info-routing.module';

import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { DatenschutzCardComponent } from './datenschutz-card/datenschutz-card.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { ImpressumCardComponent } from './impressum-card/impressum-card.component';
import { FeedbackComponent } from './feedback/feedback.component';

@NgModule({
  declarations: [
    DatenschutzCardComponent,
    DatenschutzComponent,
    ImpressumComponent,
    ImpressumCardComponent,
    FeedbackComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClarityModule,
    ClrAddonsModule,
    InfoRoutingModule,
  ],
  exports: [
    DatenschutzComponent,
    DatenschutzCardComponent,
    ImpressumComponent,
    ImpressumCardComponent,
  ],
})
export class InfoModule {}
