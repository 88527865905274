import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ClrLoadingState } from '@clr/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
})
export class FeedbackComponent {
  formFeedback = this.formBuilder.group({
    Subject: new FormControl('', { nonNullable: true }),
    Message: new FormControl('', { nonNullable: true }),
  });

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  success = false;
  error = new HttpErrorResponse({});

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private location: Location,
  ) {}

  onSubmit() {
    this.submitBtnState = ClrLoadingState.LOADING;

    const subject = this.formFeedback.get('Subject')?.value || '';
    const message = this.formFeedback.get('Message')?.value || '';

    this.authService.sendFeedback(subject, message).subscribe(
      (result) => {
        this.submitBtnState = ClrLoadingState.SUCCESS;
        this.success = true;
      },
      (error) => {
        this.submitBtnState = ClrLoadingState.ERROR;
        this.error = error;
      },
      () => {
        this.submitBtnState = ClrLoadingState.DEFAULT;
      },
    );
  }

  onAlertClose() {
    this.error = new HttpErrorResponse({});
  }

  onModalClose() {
    this.location.back();
  }
}
