import { Component, OnInit } from '@angular/core';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

@Component({
  selector: 'app-datenschutz-card',
  templateUrl: './datenschutz-card.component.html',
  styleUrls: ['./datenschutz-card.component.css'],
})
export class DatenschutzCardComponent implements OnInit {
  constructor(private breadcrumbService: ClrBreadcrumbService) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = { label: 'Datenschutzerklärung' };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);
  }
}
